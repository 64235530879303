import { Button, ButtonProps, Text } from "@chakra-ui/react";

interface ButtonDefaultProps extends ButtonProps {
  text?: string;
  attencion?: boolean;
  warning?: boolean;
  unfocused?: boolean;
  info?: boolean;
}

export function ButtonDefault({
  text,
  attencion = false,
  warning = false,
  info = false,
  unfocused = false,
  ...rest
}: ButtonDefaultProps) {
  return (
    <Button
      type="button"
      p="0"
      textColor={
        unfocused
        ? "gray.200"
        : "#FFF"
      }
      bg={
        attencion
        ? "red.500"
        : warning
        ? "#fdbb33"
        : info
        ? "blue.300"
        : unfocused
        ? "transparent"
        : "green.500"
      }
      _hover={{
        backgroundColor: attencion
          ? "red.400"
          : warning
          ? "#e9ab2f"
          : info
          ? "blue.400"
          : unfocused
          ? "transparent"
          : "green.400"
      }}
      {...rest}
    >
      <Text>{text}</Text>
    </Button>
  );
}
