import {
  Box,
  Flex,
  SimpleGrid,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
  useBreakpointValue
} from "@chakra-ui/react";
import { useCallback, useState, useEffect, useRef } from "react";
import { RiSearchLine } from "react-icons/ri";
import { Select as ChakraReactSelect } from "chakra-react-select";

import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar";
import { ClassCard } from "../../components/Card"
import { ButtonDefault } from "../../components/ButtonDefault";
import { api } from "../../services/api";
import { Event, useClassesList } from "../../services/hooks/useClassesList";
import ContentBase from "../../components/ContentBase";
import MainBase from "../../components/MainBase";
import MainActions from "../../components/MainActions";
import { RemoveAccent } from "../../utils/RemoveAccent";

type LevelData = {
  id: string;
  name: string;
}

type SelectInputProps = {
  label: string;
  value: string;
}

type LevelSelectInputProps = {
  state: {
    value: SelectInputProps;
  }
}

export function ClassesList() {
  const [events, setEvents] = useState<Event[]>([]);
  const [search, setSearch] = useState('');
  const [levelsData, setLevelsData] = useState<LevelData[]>([]);

  const levelInputRef = useRef<LevelSelectInputProps>();

  const { data, isLoading, isFetching, error } = useClassesList();

  useEffect(() => {
    if (data) {
      setEvents(data)
    }
  }, [data])

  useEffect(() => {
    api.get("/levels").then(response => {
      const levels = [
        {
          id: "",
          name: "Nível - Todos",
        },
        ...response.data
      ]

      setLevelsData(levels)
    })
  }, [])

  const handleChangeSearch = useCallback((event) => {
    setSearch(event.target.value)
  }, [])

  const handleSearch = useCallback(() => {

    if (data) {
      const hasSearch = !!search;
      const hasLevel = !!levelInputRef.current?.state && levelInputRef.current?.state.value.value !== "";

      if (hasSearch || hasLevel) {
        const searchFieldWithoutAccent = RemoveAccent(search.toLowerCase())
        const usersFiltered = data.filter(event =>
          (
            ( RemoveAccent(event.title.toLowerCase()).match(searchFieldWithoutAccent) && hasSearch ) ||
            ( RemoveAccent(event.description.toLowerCase()).match(searchFieldWithoutAccent) && hasSearch ) ||
            !hasSearch
          ) && (
            (
              hasLevel && ( event.levels.findIndex(
                eventLevel => eventLevel.level_id === levelInputRef.current?.state.value.value
              ) >= 0 || event.levels.length === 0 )
            ) || !hasLevel )
        )

        setEvents(usersFiltered)
      } else {
        setEvents(data)
      }
    }
  }, [search, data])

  const handleOnPressEnter = useCallback((event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  }, [handleSearch])

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }
      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Aulas disponíveis"
          isLoading={isLoading}
          isFetching={isFetching}
        >
          <MainActions>
            <Stack
              w="100%"
              direction={["column", null, null, null, "row"]}
              alignItems="center"
              spacing="2"
            >
              <InputGroup
                w={["100%", null, null, null, "70%"]}
                bg="white"
              >
                <InputLeftElement
                  pointerEvents="none"
                  children={<RiSearchLine fill="var(--chakra-colors-blue-900)" />}
                />
                <Input
                  name="filter"
                  type="text"
                  color="blue.900"
                  borderColor="blue.900"
                  placeholder="Procurar..."
                  focusBorderColor="blue.500"
                  onChange={handleChangeSearch}
                  onKeyPress={handleOnPressEnter}
                  _hover={{
                    borderColor: "blue.500"
                  }}
                />
              </InputGroup>

              <Box
                w={["100%", null, null, null, "20%"]}
                mr={["0", null, null, "2"]}
                color="blue.900"
                bg="white"
              >
                <ChakraReactSelect
                  name="levels"
                  defaultValue={{
                    label: "Nível - Todos",
                    value: ""
                  }}
                  options={
                    levelsData.map(level => {
                      return (
                        { value: level.id, label: level.name }
                      )
                    })
                  }
                  placeholder="Selecione o nível ..."
                  closeMenuOnSelect={true}
                  ref={levelInputRef}
                />
              </Box>

              <ButtonDefault
                w={["100%", null, null, null, "10%"]}
                text="Procurar"
                onClick={handleSearch}
              />
            </Stack>
          </MainActions>

          <Box
            h={["", "80vh"]}
            overflow="auto"
            paddingRight="1"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: "var(--chakra-colors-gray-400)",
                borderRadius: '25px',
              },
            }}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : error ? (
              <Flex direction="column">
                <Text fontSize="lg" color="blue.900">Oops...ocorreu uma falha ao obter as aulas disponíveis :(</Text>
                <Text fontSize="lg" color="blue.900">Tente novamente ;)</Text>
              </Flex>
            ) : (
              <SimpleGrid flex="1" columns={[1,null,2,2,3,4]} gap="2">
                {events.map(event => (
                  <ClassCard
                    key={event.id}
                    eventId={event.id}
                    waiting={event.waiting}
                    title={event.title}
                    levels={event.levels}
                    description={event.description}
                    date={event.startDateFormated}
                    startHour={event.startHourFormated}
                    duration={event.duration}
                    credit={event.credit}
                    eventHasSubject={event.request_subject}
                    startDateISO={event.start_date}
                    dayOfWeek={event.dayOfWeek}
                    setHighlight={event.has_highlight}
                    forTeachers={event.for_teachers}
                    descriptionFormatted={event.description_formatted || event.description}
                    classSubject={event.class_subject_id}
                  />
                ))}
              </SimpleGrid>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  )
}
