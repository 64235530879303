import {
  Box,
  Flex,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState, useRef } from "react";
import { Select as ChakraReactSelect } from "chakra-react-select";

import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar";
import { ButtonDefault } from "../../../components/ButtonDefault";
import { DownloadCSV } from "../../../components/DownloadCSV";
import { api } from "../../../services/api";
import ContentBase from "../../../components/ContentBase";
import MainBase from "../../../components/MainBase";
import MainActions from "../../../components/MainActions";
import { MainTable } from "../../../components/MainTable";
import { Column } from "react-table";

type MemberData = {
  id: string;
  name: string;
}

type SelectInputProps = {
  label: string;
  value: string;
}

type MemberSelectInputProps = {
  state: {
    value: SelectInputProps
  }
}

type MemberHistoric = {
  company_name: string;
  student_name: string;
  student_email: string;
  current_balance: number;
  gift_credit: number;
  credits_expired: number;
}

export function SquadEvents() {
  const [membersHistorics, setMembersHistorics] = useState<MemberHistoric[]>([]);
  const [companiesData, setCompaniesData] = useState<MemberData[]>([]);
  const [fileName, setFileName] = useState('historico-da-equipe.csv');
  const [isLoading, setIsLoading] = useState(false);

  const memberInputRef = useRef<MemberSelectInputProps>();

  const csvHeaders = [
    {
      label: 'Empresa',
      key: 'company_name'
    },
    {
      label: 'Aluno',
      key: 'student_name'
    },
    {
      label: 'E-mail',
      key: 'student_email'
    },
    {
      label: 'Saldo Atual',
      key: 'current_balance'
    },
    {
      label: 'Créditos Recebidos',
      key: 'gift_credit'
    },

    {
      label: 'Créditos Expirados',
      key: 'credits_expired'
    }
  ]

  useEffect(() => {
    setIsLoading(true)
    api.get('/company-members/companies').then(response => {
      setCompaniesData(response.data)
      setIsLoading(false)
    })
  }, [])

  const handleSearch = useCallback(() => {
    setIsLoading(true)

    let fileName = 'Historico-da-equipe'

    if (memberInputRef.current?.state.value){
      fileName += `-${memberInputRef.current?.state.value.label}`
    }

    fileName += '.csv'

    setFileName(fileName)

    api.get<any[]>('/company-members/members/historic', {
      params: {
        company_id: memberInputRef.current?.state.value ? memberInputRef.current?.state.value.value : ''
      }
    }).then(response => {
      setMembersHistorics(response.data);

      setIsLoading(false)
    })
  }, [memberInputRef])

  const columns: Column<MemberHistoric>[] = [
    {
      Header: "Empresa",
      accessor: "company_name",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Aluno",
      accessor: "student_name",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "E-mail",
      accessor: "student_email",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Saldo Atual",
      accessor: "current_balance",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Créditos Recebidos",
      accessor: "gift_credit",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
    {
      Header: "Créditos Expirados",
      accessor: "credits_expired",
      //  @ts-ignore TYPE NEEDS FIXING
      isSortable: true,
    },
  ];

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <Flex direction="column" h="100vh">
      { !isWideVersion && <Header /> }

      <ContentBase>
        <Sidebar />

        <MainBase
          mainTitle="Histórico de empresas"
          isLoading={isLoading}
        >
          <MainActions>
            <Flex
              w="100%"
              direction={["column", null, null, "row"]}
            >
              <Box
                w="100%"
                mr={["0", null, null, "2"]}
                color="blue.900"
              >
                <ChakraReactSelect
                  color="blue.900"
                  options={
                    companiesData.map(companyMember => {
                      return (
                        { value: companyMember.id, label: companyMember.name }
                      )
                    })
                  }
                  placeholder="Selecione a empresa"
                  closeMenuOnSelect={true}
                  ref={memberInputRef}
                />
              </Box>
            </Flex>

            <ButtonDefault
              w={["100%", null, null, "120px"]}
              minW="100px"
              mx={[null, null, null, "2"]}
              mt={["2", null, null, "0"]}
              text="Procurar"
              onClick={handleSearch}
            />

            <DownloadCSV
              text={"Exportar"}
              data={membersHistorics}
              headers={csvHeaders}
              filename={fileName}
            />
          </MainActions>

          <Box
            w="100%"
            h={["", null, null, "80vh"]}
          >
            { isLoading ? (
              <Flex justify="center">
                <Spinner color="blue.900" size="xl" />
              </Flex>
            ) : (
              <Flex
                w="100%"
                h="100%"
                direction="column"
                justifyContent="space-between"
              >
                <MainTable
                  columns={columns}
                  data={membersHistorics}
                />
              </Flex>
            )}
          </Box>
        </MainBase>
      </ContentBase>
    </Flex>
  );
}
